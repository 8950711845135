'use client'

import ButtonLink from 'components/ButtonLink'
import Page from 'components/Page'
import classes from './styles.module.scss'
import ROUTES from 'temp/routes.json'
import { useTranslation } from 'context/translationContext'

export default function Custom404() {
  const t = useTranslation()

  return (
    <Page className={classes.root}>
      <h3 className={classes.title}>{t('common.page_not_found')}</h3>
      <ButtonLink variant='contained' href={ROUTES.HOME}>
        {t('common.go_to_home')}
      </ButtonLink>
    </Page>
  )
}
